import React from 'react'
import './about_us.css'
import image from "../../../assets/images/about_us_1.jpg";
const AboutUs = () => {
    return (

            <div className='about__us__content'>
                <p>
                    Our expertise includes all kind of projects, covering everything from
                    brand-new constructions to revitalization's. <br/>
                    Our portfolio encompasses various building types, providing extensive design and
                    construction solutions
                </p>
            </div>

    )
}

export default AboutUs