import React from 'react'
import '../what_we_can_do/what_we_can_do.css'
import image from '../../../assets/images/about_us_2.jpg'

const WhatWeCanDoImage = () => {
    return (
        <div className='what_we_can__image'>
            <img src={image}/>
        </div>
    )
}

export default WhatWeCanDoImage