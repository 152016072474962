import React from 'react'
import './about.css'
import AboutUs from "./about_us/AboutUs";
import OurServices from "./our_services/OurServices";
import AboutUsImage from "./about_elements/AboutUsImage";
import OurServicesImage from "./about_elements/OurServicesImage";
import WhatWeCanDoImage from "./about_elements/WhatWeCanDoImage";
import WhatWeCanDo from "./what_we_can_do/WhatWeCanDo";

const About = () => {
    return (<section id='aboutUs'>
            <h2>About Us</h2>


            <div className='container about_us__container'>
                <AboutUsImage/>
                <AboutUs/>
            </div>


            <div className='container our_services__container'>
                <OurServices/>
                <OurServicesImage/>
            </div>


            <div className='container what_we_can__container'>
                <WhatWeCanDoImage/>
                <WhatWeCanDo/>
            </div>
        </section>
    )
}

export default About