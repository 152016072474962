import React from 'react'
import './what_we_can_do.css'
const WhatWeCanDo = () => {
    return (
        <div className='what__we__content'>
            <p>
                <h2>What Can We Do?</h2>
                From Architectural Designs to Zoning Expertise, <br/>we got your back covered when
                it comes to renewing an old site, <br/>or crafting exquisite new spaces from the ground Up.
            </p>
        </div>
    )
}

export default WhatWeCanDo