import React from 'react'
import '../projects.css'
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import image02 from '../../../assets/projects_images/011.jpg'
import image04 from '../../../assets/projects_images/013.jpg'
import image05 from '../../../assets/projects_images/014.jpg'
import image06 from '../../../assets/projects_images/015.jpg'
import image08 from '../../../assets/projects_images/017.jpg'
import image09 from '../../../assets/projects_images/018.jpg'
// import image10 from '../../../assets/projects_images/019.jpg'
import image11 from '../../../assets/projects_images/020.jpg'
// import image13 from '../../../assets/projects_images/022.jpg'
import image14 from '../../../assets/projects_images/023.jpg'
import image15 from '../../../assets/projects_images/024.jpg'
import image16 from '../../../assets/projects_images/025.jpg'
import image17 from '../../../assets/projects_images/026.jpg'
import image18 from '../../../assets/projects_images/027.jpg'
import image19 from '../../../assets/projects_images/028.jpg'
import image20 from '../../../assets/projects_images/029.jpg'


const Carousel = () => {
    return (<div className='carousel__custom'>
        <AliceCarousel autoPlay autoPlayInterval='1000' infinite='true' keyboardNavigation='true'
                       touchMoveDefaultEvents={true}>
            <img src={image02} className='slider__img' alt=""/>
            <img src={image04} className='slider__img' alt=""/>
            <img src={image05} className='slider__img' alt=""/>
            <img src={image06} className='slider__img' alt=""/>
            <img src={image08} className='slider__img' alt=""/>
            <img src={image09} className='slider__img' alt=""/>
            {/*<img src={image10} className='slider__img' alt=""/>*/}
            <img src={image11} className='slider__img' alt=""/>
            {/*<img src={image13} className='slider__img' alt=""/>*/}
            <img src={image14} className='slider__img' alt=""/>
            <img src={image15} className='slider__img' alt=""/>
            <img src={image16} className='slider__img' alt=""/>
            <img src={image17} className='slider__img' alt=""/>
            <img src={image18} className='slider__img' alt=""/>
            <img src={image19} className='slider__img' alt=""/>
            <img src={image20} className='slider__img' alt=""/>
        </AliceCarousel>
    </div>)
}

export default Carousel