import React from 'react';
import Header from './components/header/Header'
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Projects from "./components/projects/Projects";
import SocialMedia from "./components/social_media/SocialMedia";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";



const App = () => {
    return (
      <>
        <Header />
        <Nav />
        <About />
        <Projects />
        {/*<SocialMedia />*/}
        <Contact />
        <Footer />
      </>
    );
}

export default App