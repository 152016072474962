import React, { useRef } from 'react';
import emailJs from 'emailjs-com'
import './contact_form.css'

const ContactForm = () => {
    const form = useRef();
    // const name = useRef();
    // const emailRef = useRef();
    // const messageRef = useRef();
    // const submit = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        emailJs.sendForm(
            'service_59xi4bj',
            'template_dzqp6yc',
            form.current,
            'v7llzqk97pTMen9GO'
        )
          .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset();
    }
    return (
        <div className='contact__form__container'>
            <form ref={form} onSubmit={handleSubmit}>
                <input type='text' name='name' placeholder='Enter Full Name' required/>
                <input type='text' name='subject' placeholder='Enter Subject' required/>
                <input type='email' name='email' placeholder='Enter Email' required/>
                <textarea  name='message' rows='7' placeholder='Enter Message' required/>
                <button type={"submit"} className={'btn btn-primary'}>Send Message</button>
            </form>
        </div>
    )
}

export default ContactForm