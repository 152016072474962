import React, {useState} from 'react'
import './nav.css'
import {AiOutlineExperiment, AiOutlineHome} from 'react-icons/ai'
import {IoBusiness} from 'react-icons/io5'
import {IoMdContacts} from 'react-icons/io'


const Nav = () => {
    const [activeNav, setActiveNav] = useState('#');
    return (
        <nav>

            <a href='#' onClick={() => setActiveNav('#')}
               className={activeNav === '#' ? 'active' : ''}><AiOutlineHome/></a>
            <a href='#socialMedia' onClick={() => setActiveNav('#socialMedia')}
               className={activeNav === '#socialMedia' ? 'active' : ''}><IoBusiness/></a>
            <a href='#projects' onClick={() => setActiveNav('#projects')}
               className={activeNav === '#projects' ? 'active' : ''}><AiOutlineExperiment/></a>
            <a href='#contact' onClick={() => setActiveNav('#contact')}
               className={activeNav === '#contact' ? 'active' : ''}><IoMdContacts/></a>
            {/*<a href='#'><IoMdContacts/></a>*/}
        </nav>
    )
}

export default Nav