import React from 'react'
import './our_services.css'

const OurServices = () => {
    return (
        <div className='our__services__content'>
            <p>
                <h2>Our Services:</h2>
                <br/>
                <br/>
                <h3>1. Gypsum Board:</h3> With us, you can ensure accurate measurements, and
                expert installation.
                Explore the endless potential of designing with gypsum board.
                <br/>
                <br/>
                <h3>2. Framing:</h3> Unlock your imagination with our innovative and sturdy framing
                designs.
                Elevate your spaces with precision framing.
                <br/>
                <br/>
                <h3>3. Acoustical Ceiling:</h3> Using the best materials, you can guarantee not only a
                good-looking ceiling, but a one that keeps a tranquil environment for all.
                <br/>
                <br/>
                <h3>4. Painting:</h3> Transform your space with the stroke of a brush!
                You can bring your walls to life by using our high-quality paints, and precise
                techniques.
                We turn your vision into a colorful reality.
                <br/>
                <br/>
                <h3>5. Flooring:</h3> From elegant hardwood, to durable tiles, and many more options
                that suit your needs, we can provide flooring that’s both practical, and
                visually stunning.
            </p>
        </div>
    )
}

export default OurServices