import React from 'react'
import '../about_us/about_us.css'
import image from '../../../assets/images/about_us_1.jpg'

const AboutUsImage = () => {
    return (
        <div className='about__us_image'>
            <img src={image}/>
        </div>
    )
}

export default AboutUsImage