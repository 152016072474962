import React from 'react'
import './projects.css'
import Carousel from "./carousel/Carousel";
const Projects = () => {
    return (
        <section id='projects'>
            <div className='projects__'>
                <Carousel/>
            </div>
        </section>
    )
}

export default Projects