import React from 'react'
import './footer.css'
import FooterSocials from "./footer_socials/FooterSocials";
import FooterPermLink from "./footer_perm_links/FooterPermLink";

const Footer = () => {
    return (
        <footer>
            <a href="https://github.com/" className={'footer__logo'}>LMS</a>
           <FooterSocials/>

            <FooterPermLink/>

            <div className={'footer__copyright'}>
                <small>&copy LMS, All rights reserved.</small>
            </div>
        </footer>
    )
}

export default Footer