import React from 'react'
// import '../about.css'
import '../our_services/our_services.css'
import image from '../../../assets/images/about_us_3.jpg'

const OurServicesImage = () => {
    return (
        <div className='our__services__image'>
            <img src={image}/>
        </div>
    )
}

export default OurServicesImage