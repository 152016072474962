import React from 'react'
import './contact.css'
import ContactInfo from "./contact_info/ContactInfo";
import ContactForm from "./contact_form/ContactForm";
const Contact = () => {
    return (
        <section id="contact">
                {/*<h5>Get in Touch</h5>*/}
                <h2>Contact Us</h2>
            <div className={'container contact__container'}>

                    <ContactInfo/>
                    <ContactForm/>

            </div>
        </section>
    )
}

export default Contact