import React from 'react';
import './footer_perm_links.css'

const FooterPermLink = () => {
    return (

        <div className={'permLinks'}>
            <ul className={'permLinks'}>
                <li><a href={'#'}>Home</a></li>
                <li><a href={'#aboutUs'}>About</a></li>
                <li><a href={'#projects'}>Projects</a></li>
                <li><a href={'#socialMedia'}>Social Media</a></li>
                <li><a href={'#contact'}>Contact Us</a></li>
            </ul>
        </div>
    )
}
export default FooterPermLink