import React from 'react'
import './contact_info.css'
import {MdPhone, MdOutlineEmail} from "react-icons/md";

const ContactInfo = () => {
    return (
        <div id="contacts__info">
            <article className='contact_info'>
                <MdOutlineEmail className={'contact_info_icon'}/>
                <h4>Email</h4>
                <h5>mm@lmsconstruction.info</h5>
                <a href='mailto:mm@lmsconstruction.info'>Send Email</a>
            </article>

            <article className='contact_info'>
                <MdOutlineEmail className={'contact_info_icon'}/>
                <h4>Email</h4>
                <h5>sarah.raslan@lmsconstruction.info</h5>
                <a href='mailto:sarah.raslan@lmsconstruction.info'>Send Email</a>
            </article>

            <article className='contact_info'>
                <MdPhone className={'contact_info_icon'}/>
                <h4>Phone</h4>
                <h5>+1 (248) 346-4772</h5>
            </article>


        </div>
    )
}

export default ContactInfo