import React from 'react'
import './header.css'
import headerImage from '../../assets/images/header_new.jpg'
// import headerImage from '../../assets/images/header_image.jpg'


const Header = () => {
    return (
        <div className="container header__container" >
            <div className='header__title'>
                <h5>welcome to</h5>
            </div>

            <div className={'header__shadow'}>
                <div className='headerImageContainer'>
                    <img src={headerImage} alt={headerImage} className={'header__image'}/>
                </div>
            </div>

        </div>
    )
}

export default Header