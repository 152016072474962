import React from 'react';
import './footer_socials.css'

const FooterSocials = () => {
    return (
        <div className={'footer__socials'}>
            <a href={'https://instagram.com/lms_construction_llc?igshid=OGQ5ZDc2ODk2ZA=='}>Instagram</a>
            <a href={'https://www.facebook.com/KMConstructionUSA?mibextid=ZbWKwL'}>Facebook</a>
        </div>
    )
}
export default FooterSocials